import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~3],
		"/(about)/about-us": [5,[2]],
		"/(about)/about": [4,[2]],
		"/account": [11],
		"/account/listings/favourites": [~12],
		"/account/listings/my": [~13],
		"/account/profile": [~14],
		"/auth/auth-code-error": [15],
		"/auth/login": [~16],
		"/auth/logout": [17],
		"/categories/[slug]": [~18],
		"/categories/[slug]/listings": [~19],
		"/(about)/contact-us": [6,[2]],
		"/(about)/how-to": [7,[2]],
		"/(about)/koha": [8,[2]],
		"/listings": [~20],
		"/listings/create": [~24],
		"/listings/profile/[id]": [~25],
		"/listings/[id=integer]": [~21],
		"/listings/[id=integer]/edit": [~22],
		"/listings/[id=integer]/favourite": [23],
		"/maps": [26],
		"/(about)/privacy-policy": [9,[2]],
		"/(about)/terms-and-conditions": [10,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';